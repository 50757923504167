import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Layout from "~/components/Layout";
import MandateSection from "~/components/ecosystem/MandateSection";
import { breakpointIpad } from "~/components/styleUtils";
import IconSection from "~/components/ecosystem/IconSection";
import ChainlinkEcosystemDiagram from "~/components/ecosystem/ChainlinkEcosystemDiagram";
import ValidationSection from "~/components/ecosystem/ValidationSection";
import SupportSection from "~/components/ecosystem/SupportSection";

const Page = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    row-gap: 100px;
    padding: 50px 30px;

    ${breakpointIpad(`
        row-gap: 50px;
    `)}
`;

const DiagramContainer = styled.div`
    width: 100%;
    max-width: 1208px;
`;

const EcosystemPage = (): JSX.Element => (
    <>
        <Helmet>
            <title>dClimate - Ecosystem</title>

            <meta
                name="description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta property="og:title" content="dClimate - Ecosystem" />
            <meta property="og:type" content="website" />

            <meta property="og:url" content="https://dclimate.net/ecosystem" />
            <meta
                name="og:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1200x630.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x960.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="960" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x1080.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="1080" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />
            <meta property="og:site_name" content="dClimate" />

            <meta
                property="og:video"
                content="https://www.youtube.com/watch?v=nHOXUVYwWL4"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="dClimate" />
            <meta
                name="twitter:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta
                name="twitter:image"
                content="https://dclimate.net/img/meta/twitter_summary_card.png"
            />
            <meta name="twitter:image:alt" content="get data. share data." />
        </Helmet>
        <Layout>
            <Page>
                <IconSection />
                <MandateSection />
                <ValidationSection />
                <DiagramContainer>
                    <ChainlinkEcosystemDiagram />
                </DiagramContainer>
                <SupportSection />
            </Page>
        </Layout>
    </>
);

export default EcosystemPage;
