import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import { breakpointIpad } from "~/components/styleUtils";
import { Colors, FontFamily } from "~/components/ThemeVariables";

const Title = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 22px;
    color: ${Colors.ELECTRIC_BLUE};

    span {
        padding: 2px 4px;
        color: ${Colors.BLACK};
        background: ${Colors.ELECTRIC_BLUE};
    }

    ${breakpointIpad(`
        font-size: 24px;
        line-height: 33px;
    `)}
`;

const Description = styled.p`
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    max-width: 860px;
    color: ${Colors.WHITE};

    ${breakpointIpad(`
        font-size: 18px;
        line-height: 25px;
    `)}
`;

const GraphicSection = styled.div`
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    font-size: 16px;
    line-height: 20px;
    color: ${Colors.WHITE};
    font-family: ${FontFamily.SOURCE};

    ${breakpointIpad(`
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
    `)}
`;

const GraphicColumn = styled.div`
    padding: 40px 0px;
    max-width: 350px;

    > p {
        margin-top: 30px;
    }

    :last-child {
        padding-bottom: 0px;
    }
`;

const MandateSection = (): JSX.Element => (
    <section>
        <Title>
            dClimate’s <span>Mandate</span>
        </Title>
        <Description>
            End stagnation in the climate data industry and bring increased
            access, lower costs, better security, better accuracy, and both
            wider and deeper data coverage — to the benefit of all participants.
        </Description>
        <GraphicSection>
            <GraphicColumn>
                <StaticImage
                    src="./piechart.png"
                    alt="Pie Chart"
                    width={135}
                    placeholder="blurred"
                />
                <p>
                    Datasets spanning climate variables including weather,
                    energy, crops, and carbon ready for institutional use
                </p>
            </GraphicColumn>
            <GraphicColumn>
                <StaticImage
                    src="./earth.png"
                    alt="Earth"
                    width={135}
                    placeholder="blurred"
                />
                <p>
                    Global coverage including data from satellites and 120k+
                    stations with more being added every week
                </p>
            </GraphicColumn>
        </GraphicSection>
    </section>
);

export default MandateSection;
