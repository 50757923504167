import React from "react";
import styled from "styled-components";

import { breakpointIpad } from "~/components/styleUtils";
import { Colors } from "~/components/ThemeVariables";
import CoinfundLogo from "./coinfund.inline.svg";
import GiantVenturesLogo from "./giant-ventures.inline.svg";
import MccLogo from "./mcc.inline.svg";
import MulticoinCapitalLogo from "./multicoin-capital.inline.svg";
import RepublicCapitalLogo from "./republic-capital.inline.svg";

const Section = styled.section`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;

    ${breakpointIpad(`
        font-size: 16px;
        line-height: 22px;
    `)}

    color: ${Colors.WHITE};
`;

const LogoGrid = styled.div`
    margin-top: 26px;
    width: 100%;
    max-width: 649px;
    display: grid;
    grid-template-columns: repeat(5, minmax(auto, 105px));
    grid-template-rows: 74px;
    column-gap: 36px;
    place-items: center;

    ${breakpointIpad(`
        grid-template-rows: 38px;
    `)}
`;

const SupportSection = (): JSX.Element => (
    <Section>
        <p>
            dClimate is proud to be supported by leading investors and venture
            capital firms in the blockchain and traditional finance sectors. Our
            strategic partners include CoinFund, Giant Ventures, Multicoin
            Capital, Republic Capital, and Mark Cuban.
        </p>
        <LogoGrid>
            <CoinfundLogo />
            <GiantVenturesLogo />
            <MulticoinCapitalLogo />
            <RepublicCapitalLogo />
            <MccLogo />
        </LogoGrid>
    </Section>
);

export default SupportSection;
