import React from "react";
import styled from "styled-components";

import { RgbColors, Gradient } from "~/components/ThemeVariables";
import ArbolLogo from "~/components/common/logos/arbol.inline.svg";
import ChainlinkLogo from "~/components/common/logos/chainlink.inline.svg";
import PolygonLogo from "~/components/common/logos/polygon.inline.svg";

const Section = styled.section`
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 25px 20px;
    width: 100%;
    max-width: 985px;
    box-shadow: 0px 4px 20px 0px #00000040;
    backdrop-filter: blur(60px);
    background: ${Gradient.RADIAL};
    filter: drop-shadow(0px 4px 20px rgba(${RgbColors.BLACK}, 0.25));
`;

const LogoGrid = styled.div`
    width: 100%;
    max-width: 684px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 45px;
    column-gap: 30px;
    place-items: center;

    > svg:nth-child(-n + 2) {
        margin-right: 30px;
    }
`;

const IconSection = (): JSX.Element => (
    <Section>
        <LogoGrid>
            <ArbolLogo />
            <PolygonLogo />
            <ChainlinkLogo />
        </LogoGrid>
    </Section>
);

export default IconSection;
