import React from "react";
import styled from "styled-components";

import { breakpointIpad } from "~/components/styleUtils";
import { Colors } from "~/components/ThemeVariables";

const Title = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    color: ${Colors.ELECTRIC_BLUE};
    margin-bottom: 22px;

    span {
        padding: 2px 4px;
        color: ${Colors.BLACK};
        background: ${Colors.ELECTRIC_BLUE};
    }

    ${breakpointIpad(`
        font-size: 24px;
        line-height: 33px;
    `)}
`;

const Description = styled.p`
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    max-width: 860px;
    color: ${Colors.WHITE};

    span {
        color: ${Colors.ELECTRIC_BLUE};
        font-weight: 600;
    }

    ${breakpointIpad(`
        font-size: 18px;
        line-height: 25px;
    `)}
`;

const ValidationSection = (): JSX.Element => (
    <section>
        <Title>
            Decentralized <span>Data Validation</span>
        </Title>
        <Description>
            dClimate creates an&nbsp;<span>open marketplace</span>&nbsp;where
            all data and forecasts have impartial “skill scores’’ to make it
            easier for consumers to shop for climate data.
            <br />
            <br />
            The dClimate marketplace&nbsp;
            <span>leverages Chainlink’s widely adopted oracle network</span>
            &nbsp;for retrieving climate data and validating associated skill
            scores on-chain.
        </Description>
    </section>
);

export default ValidationSection;
